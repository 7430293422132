import React from 'react';
import {compose} from 'recompose';
import Layout from '@p14/layouts';
import styled from 'styled-components';
import * as Components from '@p14/components';
import * as HOC from '@p14/HOC';
import config from '@p14/config';
import data from '@p14/texts/technical';

export const Container = styled.section`
  overflow: hidden;
`;

export const Article = styled.article`
  float: left;
  width: 256px;
  margin-bottom: 24px;

  @media ${config.media.desktopOnly} {
    &:not(:last-child) {
      margin-right: 90px;
    }

    &:nth-child(4) {
      overflow: hidden;
      float: none;
    }

    &:last-child {
      float: right;
    }
  }

  @media ${config.media.tabletOnly} {
    float: left;
    width: 320px;

    &:first-child,
    &:nth-child(4) {
      margin-right: 50px;
    }

    &:nth-child(4) {
      clear: left;
    }

    &:last-child {
      float: right;
      margin-top: -205px;
    }
  }

  @media ${config.media.mobile} {
    float: none;
    width: 100%;
    border-bottom: 0;
  }
`;

export const H2 = styled.h2`
  margin: 0 0 10px;
  text-transform: uppercase;
  font-family: Austin;
  font-size: 14px;
  color: #b48b72;
  letter-spacing: 0.12px;
  text-align: center;
`;

export const UL = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const LI = styled.li`
  font-family: Geometria;
  font-size: 14px;
  color: #9a9a9a;
  line-height: 20px;
  margin-bottom: 20px;
`;

export const Text = styled.p`
  font-family: Geometria;
  font-size: 14px;
  color: #9a9a9a;
  line-height: 20px;
  margin: 0;
`;

export const Item = styled.div`
  margin-bottom: 20px;
`;

export const TechnicalScreen = ({tr}) => (
  <Layout>
  <Components.Container>
    <Components.H1>{tr('title')}</Components.H1>

    <Container>
      {tr('sections').slice(0, 2).map((section, index) => (
        <Article key={index}>
          <H2>{section.title}</H2>
          <UL>
            {section.texts.map((text, index) => (
              <LI key={index}>{text}</LI>
            ))}
          </UL>
        </Article>
      ))}

      <Article>
        {tr('sections').slice(2).map((section, index) => (
          <Article key={index}>
            <H2>{section.title}</H2>
            <UL>
              {section.texts.map((text, index) => (
                <LI key={index}>{text}</LI>
              ))}
            </UL>
          </Article>
        ))}
      </Article>
    </Container>
  </Components.Container>
  </Layout>
);

export const enhance = compose(
  HOC.withTranslation(data),
);

export default enhance(TechnicalScreen);
