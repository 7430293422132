export default {
  ru: {
    title: "Характеристики",
    sections: [
      {
        title: "Дом",
        texts: [
          "Закрытая охраняемая территория",
          "24/7 услуги консьержа в здании",
          "Подземный паркинг на 61 машино‑место",
          "Камины в лобби",
          "Ландшафтный дизайн территории и террас",
          "Библиотека для жителей дома на 1 этаже",
          "Фитнес-зал, оснащенный оборудованием TECHNOGYM",
          "Комната для хранения колясок и велосипедов на 1 этаже",
          "Широкополосный доступ в интернет Wi-Fi в лобби",
          "Фасады, облицованные кирпичом ручной формовки",
          "Видеонаблюдение, охранная сигнализация, контроль доступа",
          "Лифты KONE с индивидуальной отделкой лифтовых кабин",
          "Автоматическая система пожаротушения, противодымная вентиляция",
          "Многоуровневая система очистки воды, резервная система бесперебойного обеспечения горячей водой",
          "Автоматизация и диспетчеризация основных систем здания",
        ],
      },
      {
        title: "Технические детали",
        texts: [
          "Система индивидуальной и принудительной приточной и вытяжной механической вентиляции с подогревом воздуха",
          "Центральное кондиционирование, охлаждение воздуха чиллерами (производство CARRIER), с возможностью индивидуальной регулировки температуры в каждом помещении",
          "Многоуровневая система очистки воды",
          "Автоматическая система пожаротушения, противодымная вентиляция",
          "Автоматическое регулирование температуры приборов отопления",
          "Автоматизация и диспетчеризация основных систем здания",
          "Видеонаблюдение, охранная сигнализация, контроль доступаная система бесперебойного обеспечения горячей водой",
        ],
      },
      {
        title: "Кухня",
        texts: [
          "Кухонные шкафы из шпона дерева производства Италии",
          "Кухонные поверхности из кварцита",
          "Кухонная техника MIELE: посудомоечная машина, варочная панель, духовой шкаф, вытяжка, микроволновая печь, холодильник",
          "Раковины Franke Planer",
          "Смесители Dornbracht",
          "Раздельные каналы для кухонной вытяжки и общеобменной вентиляции",
        ],
      },
      {
        title: "Санузлы",
        texts: [
          "Мебель для ванной, произведенная в Италии по дизайну компании HMS",
          "Санитарный фаянс DURAVIT",
          "Обогрев полов",
          "Мрамор Bianco Venus",
          "Смесители и аксессуары DORNBRACHT",
        ],
      },
      {
        title: "Апартаменты",
        texts: [
          "53 апартамента",
          "9 этажей",
          "Алюминиево-деревянные окна: анодированный алюминий снаружи и дуб внутри",
          "Дубовый паркет HERRINGBONE",
          "Центральное кондиционирование, охлаждение воздуха чиллерами (производство CARRIER), с возможностью индивидуальной регулировки температуры в каждом помещении",
          "Система индивидуальной и принудительной приточной и вытяжной механической вентиляции с подогревом и охлаждением воздуха",
          "Индивидуальный учет потребляемых энергоресурсов по каждому апартаменту",
          "Широкополосный доступ в интернет в HD качестве, неограниченное количество цифровых телефонных линий",
          "Высота потолков 3.2 м в готовых помещениях",
          "Стиральная машина и сушильный шкаф MIELE",
          "Встроенные гардеробные, изготовленные по дизайну HESSE MACKEN STUDIO",
        ],
      },
    ],
    footer: [
      {
        title: "Архитектура здания",
        caption: "Green Design Society",
      },
      {
        title: "Интерьеры",
        caption: "Hesse Macken Studio London",
      },
      {
        title: "Застройщик",
        caption: "Elbert Development Group",
      },
    ],
  },
  en: {
    title: "Характеристики",
    sections: [
      {
        title: "Дом",
        texts: [
          "Закрытая охраняемая территория",
          "24/7 услуги консьержа в здании",
          "Подземный паркинг на 61 машино-место",
          "Камины в лобби",
          "Ландшафтный дизайн территории и террас",
          "Библиотека для жителей дома на 1 этаже",
          "Фитнес-зал, оснащенный оборудованием TECHNOGYM",
          "Комната для хранения колясок и велосипедов на 1 этаже",
          "Широкополосный доступ в интернет Wi-Fi в лобби",
          "Фасады, облицованные кирпичом ручной формовки",
          "Видеонаблюдение, охранная сигнализация, контроль доступа",
          "Лифты KONE с индивидуальной отделкой лифтовых кабин",
          "Автоматическая система пожаротушения, противодымная вентиляция",
          "Многоуровневая система очистки воды, резервная система бесперебойного обеспечения горячей водой",
          "Автоматизация и диспетчеризация основных систем здания",
        ],
      },
      {
        title: "Кухня",
        texts: [
          "Кухонные шкафы из шпона дерева производства Италии",
          "Кухонные поверхности из кварцита",
          "Кухонная техника MIELE: посудомоечная машина, варочная панель, духовой шкаф, вытяжка, микроволновая печь, холодильник",
          "Раковины Franke Planer",
          "Смесители Dornbracht",
          "Раздельные каналы для кухонной вытяжки и общеобменной вентиляции",
        ],
      },
      {
        title: "Санузлы",
        texts: [
          "Мебель для ванной, произведенная в Италии по дизайну компании HMS",
          "Санитарный фаянс DURAVIT",
          "Обогрев полов",
          "Мрамор Bianco Venus",
          "Смесители и аксессуары DORNBRACHT",
        ],
      },
      {
        title: "Апартаменты",
        texts: [
          "53 апартамента",
          "9 этажей",
          "Алюминиево-деревянные окна: анодированный алюминий снаружи и дуб внутри",
          "Дубовый паркет HERRINGBONE",
          "Центральное кондиционирование, охлаждение воздуха чиллерами (производство CARRIER), с возможностью индивидуальной регулировки температуры в каждом помещении",
          "Система индивидуальной и принудительной приточной и вытяжной механической вентиляции с подогревом и охлаждением воздуха",
          "Индивидуальный учет потребляемых энергоресурсов по каждому апартаменту",
          "Широкополосный доступ в интернет в HD качестве, неограниченное количество цифровых телефонных линий",
          "Высота потолков 3.2 м в готовых помещениях",
          "Стиральная машина и сушильный шкаф MIELE",
          "Встроенные гардеробные, изготовленные по дизайну HESSE MACKEN STUDIO",
        ],
      },
    ],
    footer: [
      {
        title: "Архитектура здания",
        caption: "Green Design Society",
      },
      {
        title: "Интерьеры",
        caption: "Hesse Macken Studio London",
      },
      {
        title: "Застройщик",
        caption: "Elbert Development Group",
      },
    ],
  },
};
